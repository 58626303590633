<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 分析师报名 </template>
      <template #input>
        <!-- <span class="tag">标签名称：</span> -->
        <a-input placeholder="请输入姓名" v-model="keyWord" />
        <a-select style="width: 120px" v-model="states" placeholder="状态">
          <a-select-option value="1"> Inactive </a-select-option>
          <a-select-option value="2"> Active </a-select-option>
          <a-select-option value="3"> Expired </a-select-option>
        </a-select>
        <a-select style="width: 120px" v-model="types" placeholder="证书类型">
          <a-select-option value="1"> RBT </a-select-option>
          <a-select-option value="2"> BCaBA </a-select-option>
          <a-select-option value="3"> BCBA </a-select-option>
          <a-select-option value="4"> BCBA-D </a-select-option>
        </a-select>
        <a
          :href="$config.target + '/hxclass-management/hx_sing_up/exportHxSingUp'"
          target="_blank"
          rel="noopener noreferrer"
          class="left"
        >
          <a-button style="margin-right: 10px">导出表格</a-button>
        </a>
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
        <a-button class="all_boder_btn" @click="onAdd">新建</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 手机号 -->
        <template slot="tellPhone" slot-scope="text">
          {{ text ? $encryption('user:infomation:sensitivequery','phone',text) : '-' }}
        </template>
        <template slot="type" slot-scope="item, row">
          <div style="text-align: center">
            {{ row.type == 0 ? "后台创建" : "用户填写" }}
          </div>
        </template>
        <template slot="state" slot-scope="item, row">
          <div style="text-align: center">
            {{ row.state == 0 ? "已启用" : "已停用" }}
          </div>
        </template>
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a @click="edit(item)">编辑</a>
            <span>|</span>
            <a v-if="item.state != 0" @click="changeState(item.workUnitId, 0)"
              >启用</a
            >
            <a v-else @click="changeState(item.workUnitId, 1)">停用</a>
            <span v-if="item.isFollow != 1">|</span>
            <a @click="deleteData(item)" v-if="item.isFollow != 1">删除</a>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 新建/编辑 -->
    <a-modal
      v-model="visible"
      okText="确认"
      :title="(state == 1 ? '新建' : '编辑') + '工作单位'"
      width="360px"
      @ok="handleOk"
    >
      <div class="modal_box">
        <span class="tag">工作单位：</span>
        <a-input type="text" v-model="label" placeholder="请输入工作单位" />
      </div>
    </a-modal>
    <!-- 数据弹窗 -->
    <LeadingIn
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "60px",
    align: "center",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "姓名",
    ellipsis: "true",
    dataIndex: "chineseName",
    scopedSlots: { customRender: "chineseName" },
  },
  {
    title: "性别",
    ellipsis: "true",
    dataIndex: "sex",
    scopedSlots: { customRender: "sex" },
  },
  {
    title: "证书类型",
    ellipsis: "true",
    dataIndex: "certificateType",
    scopedSlots: { customRender: "certificateType" },
  },
  {
    title: "状态",
    ellipsis: "true",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },

  {
    title: "手机号",
    ellipsis: "true",
    dataIndex: "tellPhone",
    scopedSlots: { customRender: "tellPhone" },
  },
  {
    title: "邮箱",
    ellipsis: "true",
    dataIndex: "email",
    scopedSlots: { customRender: "email" },
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
const columnsss = [
  {
    title: "工作单位",
    align: "center",
    dataIndex: "name",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
export default {
  // 可用组件的哈希表
  components: { HeaderBox, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      keyWord: "", //标签关键字搜索
      labelId: "", //标签id
      label: null, //新增或编辑标签
      state: 1, // 1新建，2编辑
      visible: false,
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      states: undefined,
      types: undefined,
      workUnitId: null,
      // 弹框
      columnsss,
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
    };
  },
  // 事件处理器
  methods: {
    modelhandleCancel() {
      this.importVisible = false;
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // tab切换
    callback(e) {},
    // 跳详情页
    goDetails() {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { id: 1 },
      });
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.getData();
    },
    // 新建
    onAdd() {
      this.state = 1;
      this.label = "";
      this.visible = true;
      this.workUnitId = null;
    },
    // 修改状态
    changeState(e, r) {
      // e是状态，r是id
      this.$ajax({
        url: "/hxclass-management/work/update/state",
        method: "put",
        params: {
          state: r,
          jobId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getData();
        } else {
          this.$message.success(res.message);
        }
      });
    },
    // 获取数据
    getData() {
      this.$ajax({
        url: "/hxclass-management/hx_sing_up/selManagerList",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          status: this.states,
          chineseName: this.keyWord,
          certificateType: this.types,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    edit(e) {
      // console.log(e);
      this.state = 2;
      this.visible = true;
      this.workUnitId = e.workUnitId;
      this.label = e.name;
    },
    // 确定新建
    handleOk() {
      if (!this.label) {
        this.$message.warning("请正确填写");
        return;
      }
      let url = "";
      let params = {};
      let method = "";
      // 新增
      if (this.state == 1) {
        url = "/hxclass-management/work/add";
        method = "POST";
      }
      // 编辑
      else if (this.state == 2) {
        url = "/hxclass-management/work/update";
        method = "PUT";
      }

      this.$ajax({
        url,
        method,
        params: {
          name: this.label,
          workUnitId: this.workUnitId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.visible = false;
          this.label = "";
          this.getData();
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 导入基础信息
    fileAction(e) {
      this.$ajax({
        url: "/hxclass-management/work/excel",
        method: "POST",
        headers: [{ type: "file" }],
        params: {
          file: e.file,
          name: "file",
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.successList = res.data.success;
          this.failureList = res.data.fail;
          this.importVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 删除标签
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "确定删除该标签吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/work/del?workUnitId=" + item.workUnitId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getData();
              }
            });
        },
        onCancel() {},
      });
    },

    // 下载错误excel
    // downloadExcel() {
    //   if (!this.failureList.length) {
    //     return;
    //   }
    //   window.open(
    //     this.$config.target +
    //       "/hxclass-management/exam/certif/export/certifinfo?errorJson=" +
    //       encodeURIComponent(JSON.stringify(this.failureList)) +
    //       "&type=1"
    //   );
    // },

    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      this.$ajax({
        url: "/hxclass-management/work/export/work/wrong",
        method: "post",
        headers: [{ type: "file" }],
        params: {
          errorJson: JSON.stringify(this.failureList),
        },
        responseType: "blob",
      }).then((res) => {
        this.blobDownload(res, "错误文件");
      });
    },

    // blob文件转excel
    blobDownload(data, name) {
      let m = this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name + ".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },

    // 导入正确数据
    importSuccessList() {
      // let arr = [];
      // arr = JSON.stringify(this.successList);
      this.$ajax({
        url: "/hxclass-management/work/import/insert",
        method: "POST",
        params: {
          list: JSON.stringify(this.successList),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getData();
          this.importVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取数据
    this.getData();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.modal_box {
  display: flex;
  align-items: center;
  .tag {
    flex-shrink: 0;
  }
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  // /deep/.ant-table {
  //   border: 1px solid #eeeeee;
  //   box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  // }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
.left {
  margin-left: 16px;
}
</style>
